.modalContainer {
    position:fixed;
    transform-origin: center;
    z-index:11;
    height: 354px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 530px;
        pointer-events: none;
        overflow: hidden;
        
    }
    .modalWindow {
    z-index:100000;
    margin:0;
    padding:0;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    /* clip-path: polygon(1.75em 0%, 
            100% 0%, 
            100% calc(100% - 1.5em),
            calc(100% - 1.75em) 100%, 
            0 100%, 
            0% 1.5em); */
    }
    .modal-overlay {
        width: 100%;
        height: 100%;
        position: fixed;
        bottom: 0;
        /* background-color: red;    */
    }
    .modal-overlay img {
        position: absolute;
        bottom: 0;
    }
    .modalSlider {
        height: 100%;
        width : 100%;
       
        right: 0;
        /* background-color: red; */
        position: fixed;
        transition: all 500ms cubic-bezier(0.76, 0, 0.24, 1);
    }
    .modal {
        width: 100%;
        /* background-color: red; */
        height: 100%;
        /* z-index: 1000; */
    }
    .modal img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .modalCursor, .cursorLabel{
        width: 140px;
        height: 40px;
        border-radius: 40px;
        background-color: #ffffff78;
        backdrop-filter: blur(3px);
        color: var(--black);
        position: fixed;
        z-index: 1000;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-weight: 300;
        pointer-events: none;
    }
    
    
    .cursorLabel{
        background-color: transparent;
        font-size: 14px;
        font-weight: 500;
        /* text-transform: uppercase; */
    }

    @media only screen and (max-width: 468px) {
        .modalContainer {
            display: none;
        }
    }