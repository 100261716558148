@import url('https://fonts.googleapis.com/css2?family=Hanken+Grotesk:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bai+Jamjuree:wght@400;500&display=swap');
@font-face {
    font-family:  "Neue Haas";
    src: url(../assets/fonts/NeueHaasDisplayRoman.ttf);
}
@font-face {
    font-family:  "Osiris";
    src: url(../assets/fonts/Osiris.woff2);
}
@font-face {
    font-family:  "Stratos";
    src: url(../assets/fonts/stratosweb-bold.woff);
    font-weight: bold;
}
@font-face {
    font-family:  "Stratos";
    src: url(../assets/fonts/stratosweb-thin.woff);
    font-weight: thin;
}
@font-face {
    font-family:  "Stratos";
    src: url(../assets/fonts/stratosweb-regular.woff);
    font-weight: normal;
}
@font-face {
    font-family:  "Kusanagi";
    src: url(../assets/fonts/Kusanagi.otf);
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    // cursor: none;
}
html {
    scroll-behavior: initial;
    
}

.root{
    
    position: relative;
}
html, body {
    min-height: 100%;
    height: auto;
    
}
:root {
	--black: #111111;
    --gray: #222222;
	--white: #ffffff;
    --secondaryColor : #b0ff05;
    --mainColor : #111111;
    // sizing 
    --xs-sizing: 8px; 
    --s-sizing: 16px; 
    --m-sizing: 24px; 
    --l-sizing: 32px; 
    --xl-sizing: 64px; 
    --xxl-sizing: 120px; 
    --padding : 50px;

    --spacer:80px;
}
::selection {
    background-color: var(--secondaryColor);
    color: var(--black);
    -webkit-text-fill-color: var(--black);
}
.container {
    width: 95%;
    margin: 0 auto;
}
.container-small {
    width: 95%;
    margin: 0 auto;
}

body {
    // font-family:'Bai Jamjuree', sans-serif;
    font-family: "Stratos", sans-serif;
    background-color: var(--black);
    color: var(--white);
    overflow-x: hidden;
}

li {
    list-style: none;
}
a {
    font-family: "Stratos", sans-serif;
    text-decoration: none;
    color: var(--white);
    font-weight: bold;
    text-transform: uppercase;
}
h1, h2, h3 {
    // font-family: "Osiris", "Neue Haas", sans-serif;
    // font-family: "Hanken Grotesk", sans-serif;
    font-family: "Stratos", sans-serif;
    text-transform: uppercase;
    font-weight: bold;
}

.section-title {
    text-align: center;
    font-size: 3rem;
    color: var(--white);
    padding: var(--padding);
    // background-image:linear-gradient(0deg, rgba(0, 0, 0, 0.379) 35%, white 50%);
        // background-clip: text;
        // -webkit-background-clip: text;
        // -webkit-text-fill-color: transparent;
}



/*  SCROLLBAR   */
*::-webkit-scrollbar {
    width: 4px;
    scrollbar-color: var(--secondaryColor);
}
*::-webkit-scrollbar-thumb {
    background: var(--secondaryColor);
  }
 