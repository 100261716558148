@import url("https://fonts.googleapis.com/css2?family=Hanken+Grotesk:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bai+Jamjuree:wght@400;500&display=swap");
@font-face {
  font-family: "Neue Haas";
  src: url(../assets/fonts/NeueHaasDisplayRoman.ttf);
}
@font-face {
  font-family: "Osiris";
  src: url(../assets/fonts/Osiris.woff2);
}
@font-face {
  font-family: "Stratos";
  src: url(../assets/fonts/stratosweb-bold.woff);
  font-weight: bold;
}
@font-face {
  font-family: "Stratos";
  src: url(../assets/fonts/stratosweb-thin.woff);
  font-weight: thin;
}
@font-face {
  font-family: "Stratos";
  src: url(../assets/fonts/stratosweb-regular.woff);
  font-weight: normal;
}
@font-face {
  font-family: "Kusanagi";
  src: url(../assets/fonts/Kusanagi.otf);
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: initial;
}

.root {
  position: relative;
}

html, body {
  min-height: 100%;
  height: auto;
}

:root {
  --black: #111111;
  --gray: #222222;
  --white: #ffffff;
  --secondaryColor: #b0ff05;
  --mainColor: #111111;
  --xs-sizing: 8px;
  --s-sizing: 16px;
  --m-sizing: 24px;
  --l-sizing: 32px;
  --xl-sizing: 64px;
  --xxl-sizing: 120px;
  --padding: 50px;
  --spacer:80px;
}

::-moz-selection {
  background-color: var(--secondaryColor);
  color: var(--black);
  -webkit-text-fill-color: var(--black);
}

::selection {
  background-color: var(--secondaryColor);
  color: var(--black);
  -webkit-text-fill-color: var(--black);
}

.container {
  width: 95%;
  margin: 0 auto;
}

.container-small {
  width: 95%;
  margin: 0 auto;
}

body {
  font-family: "Stratos", sans-serif;
  background-color: var(--black);
  color: var(--white);
  overflow-x: hidden;
}

li {
  list-style: none;
}

a {
  font-family: "Stratos", sans-serif;
  text-decoration: none;
  color: var(--white);
  font-weight: bold;
  text-transform: uppercase;
}

h1, h2, h3 {
  font-family: "Stratos", sans-serif;
  text-transform: uppercase;
  font-weight: bold;
}

.section-title {
  text-align: center;
  font-size: 3rem;
  color: var(--white);
  padding: var(--padding);
}

/*  SCROLLBAR   */
*::-webkit-scrollbar {
  width: 4px;
  scrollbar-color: var(--secondaryColor);
}

*::-webkit-scrollbar-thumb {
  background: var(--secondaryColor);
}

@media only screen and (max-width: 468px) {
  .section-title {
    font-size: 28px;
    margin-bottom: 28px;
  }
  .project-title h1 {
    font-size: 2px;
  }
}
.root {
  overflow-x: hidden;
  position: relative;
}

.project-info {
  position: relative;
  display: flex;
  margin-top: var(--spacer);
  padding: var(--padding);
  flex-direction: column;
  height: 30vh;
  justify-content: space-between;
  background-color: var(--black);
}
.project-info .project-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10%;
}
.project-info .project-title h1 {
  font-size: 96px;
}
.project-info .project-title h2 {
  font-size: 81px;
}
.project-info .about-client {
  display: flex;
}
.project-info .about-client .description {
  width: 50%;
}
.project-info .about-client .project-role {
  width: 50%;
}
.project-info .about-client h2 {
  width: 40%;
  font-size: 12px;
  text-transform: uppercase;
  font-family: "Neue Haas", sans-serif;
  font-weight: 400;
}

.blur2 {
  position: absolute;
  overflow: hidden;
  left: -20%;
  transform: scale(1.4);
  pointer-events: none;
  opacity: 0.3;
}

.projectLink:hover ~ .cursor-img {
  opacity: 0;
}

.project-img-wrapper {
  background-color: var(--black);
  width: 100vw;
  height: 90vh;
  margin: 0 auto;
  overflow: hidden;
  display: flex;
  align-items: center;
  margin-bottom: 60px;
}
.project-img-wrapper img {
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  height: 100%;
}

.two-col-img {
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: var(--black);
  padding: 60px 0;
}
.two-col-img img {
  width: 49%;
}
.two-col-img .video {
  width: 49%;
  height: auto;
  background-color: red;
}
.two-col-img .video video {
  width: 100%;
  height: auto;
}

.one-col-img {
  padding: 60px 0;
  background-color: var(--black);
  width: 100%;
}
.one-col-img img {
  width: 100%;
  margin-bottom: 40px;
}
.one-col-img .video {
  aspect-ratio: 1/1;
  background-color: red;
}
.one-col-img .video video {
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 468px) {
  .project-info {
    height: 20vh;
  }
  .project-title {
    padding-top: 30px;
    padding-bottom: 30px;
    align-items: left !important;
    flex-direction: column !important;
    margin-bottom: 10px;
  }
  .project-title .blur2 {
    position: absolute;
    left: -20%;
    transform: scale(1.4);
    pointer-events: none;
    opacity: 0.4;
  }
  .project-title h1 {
    font-size: 40px !important;
    margin-bottom: 20px;
  }
  .project-title h2 {
    font-size: 28px !important;
  }
  .project-img-wrapper {
    width: 100%;
    height: auto;
    aspect-ratio: 16/9;
    margin-bottom: 50px;
  }
  .container-small {
    width: 95%;
  }
  .two-col-img {
    margin: 0;
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
  .two-col-img .video {
    width: 100%;
    margin-bottom: 30px;
  }
  .two-col-img img {
    width: 100%;
    margin-bottom: 60px;
  }
  .two-col-img img:last-child {
    margin-bottom: 0px;
  }
}/*# sourceMappingURL=main.css.map */