@import "Library";
@import "Responsive";

.root {
    overflow-x: hidden;
    position: relative; 
}


.project-info {
    position: relative;
    display: flex;
    margin-top: var(--spacer);
    padding: var(--padding);
    flex-direction: column;
    height: 30vh;
    // overflow: hidden;
    justify-content: space-between;
    background-color:var(--black);
    
    // background-color: red;

    .project-title{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        // align-items: center;
        margin-bottom: 10%;


        h1 {
            font-size: 96px;
        }
        h2 {
            font-size: 81px;
        }
    }
    .about-client {
        display: flex;
        // background-color: blue;  
        .description {
            width: 50%;
        }
        .project-role {
            width: 50%;
        }
        h2 {
            width: 40%;
            font-size: 12px;
            text-transform: uppercase;
            font-family: "Neue Haas", sans-serif;
            font-weight: 400;
        }
    }
}
.blur2 {
            position: absolute;
            overflow: hidden;
            left:-20%;
            transform: scale(1.4);
            pointer-events: none;
            opacity: .3;
          }

.projectLink:hover ~ .cursor-img {
    opacity: 0;
}
// .project-title {
//     margin-top: var(--spacer);
//     display: flex;
//     padding: var(--padding);
//     flex-direction: column;
//     align-items: center;
//     gap: var(--xs-sizing);
//     .blur2 {
//         position: absolute;
//         left:-20%;
//         transform: scale(1.4);
//         pointer-events: none;
//         opacity: .4;
//       }
//     h1 {
//         font-size: 44px;
//         margin-bottom: 20px;
//         background-image:linear-gradient(270deg, black, white 69%);
//         background-clip: text;
//     }
//     h2 {
//         font-size: 24px;
//         font-family: "Neue Haas", sans-serif;
//         color: #636363;
//     }
// }

.project-img-wrapper {
    background-color:var(--black);
    width: 100vw;
    height: 90vh;
    margin: 0 auto;
    overflow: hidden;
    display: flex;
    align-items: center;
    margin-bottom: 60px;
    img {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
}

.two-col-img {
    // background-color:var(--black);
    // margin: var(--spacer) ;
    width: 100%;
    display: flex;
    justify-content: space-between;
    background-color:var(--black);
    // margin-bottom: 60px;
    padding: 60px 0;
    img {
        // height: 100vh;
        width: 49%;
    }
    .video {
        width: 49%;
        height: auto;
        background-color: red;
        video {
            width: 100%;
            height: auto;
        }
    }
}
.one-col-img {
    padding: 60px 0;
    // height: 180px;
    background-color:var(--black);  
    width: 100%;
    img {
        width: 100%;
        margin-bottom: 40px;
    }
    .video {
        aspect-ratio: 1/1;
        background-color: red;
        video {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }
}

@media only screen and (max-width: 468px) {
    .project-info {
        height: 20vh;
        // padding: ;
    }
    .project-title {
        padding-top: 30px;
        padding-bottom: 30px;
        align-items: left !important;
        flex-direction: column !important;
        margin-bottom: 10px;
        .blur2 {
            position: absolute;
            left:-20%;
            transform: scale(1.4);
            pointer-events: none;
            opacity: .4;
          }
        h1 {
            font-size: 40px !important;
            // margin-top: 50px;
            margin-bottom: 20px;
        }
        h2 {
             font-size: 28px !important;
        }

    }

    .project-img-wrapper {
        width: 100%;
        height: auto;
        aspect-ratio: 16/9;
        margin-bottom: 50px;
    }
    .container-small {
        width: 95%;
    }
    .two-col-img {
        margin: 0;
        width: 100%;
        flex-direction: column;
        align-items: center;
        .video {
            width: 100%;
            margin-bottom: 30px;
        }
        img {
            width: 100%;
            margin-bottom: 60px;
            &:last-child {
                margin-bottom: 0px;
            }
        }
    }
}