@media only screen and (max-width: 468px) {
    .section-title {
        font-size: 28px;
        margin-bottom: 28px;
    }
    .project-title {
        h1 {
            font-size: 2px;
        }
    }
}